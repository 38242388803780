import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(_vm.hasDuplicates)?_c(VAlert,{attrs:{"type":"warning"}},[_vm._v("There is a duplicate entry for this person in the subgroup")]):_vm._e(),_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.types,"item-text":"title","item-value":"id","menu-props":{ maxHeight: '400' },"label":"Person Type","error-messages":errors,"clearable":!_vm.isNewRecord},model:{value:(_vm.personLocal.people_types_id),callback:function ($$v) {_vm.$set(_vm.personLocal, "people_types_id", $$v)},expression:"personLocal.people_types_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.status,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Status","clearable":""},model:{value:(_vm.personLocal.people_statuses_id),callback:function ($$v) {_vm.$set(_vm.personLocal, "people_statuses_id", $$v)},expression:"personLocal.people_statuses_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.genders,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Gender","clearable":""},model:{value:(_vm.personLocal.genders_id),callback:function ($$v) {_vm.$set(_vm.personLocal, "genders_id", $$v)},expression:"personLocal.genders_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required|min:2|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"First Name","error-messages":errors},on:{"change":_vm.onInputChanged},model:{value:(_vm.personLocal.first_name),callback:function ($$v) {_vm.$set(_vm.personLocal, "first_name", $$v)},expression:"personLocal.first_name"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Surname","rules":"required|min:2|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Surname","error-messages":errors},on:{"change":_vm.onInputChanged},model:{value:(_vm.personLocal.surname),callback:function ($$v) {_vm.$set(_vm.personLocal, "surname", $$v)},expression:"personLocal.surname"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Date of birth","rules":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Date of birth","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.personLocal.dob),callback:function ($$v) {_vm.$set(_vm.personLocal, "dob", $$v)},expression:"personLocal.dob"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Email"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Email"},model:{value:(_vm.personLocal.email),callback:function ($$v) {_vm.$set(_vm.personLocal, "email", $$v)},expression:"personLocal.email"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Mobile"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Mobile"},model:{value:(_vm.personLocal.mobile),callback:function ($$v) {_vm.$set(_vm.personLocal, "mobile", $$v)},expression:"personLocal.mobile"}})],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Notes"}},[_c(VTextarea,{attrs:{"label":"Notes","rows":"3","filled":"","auto-grow":""},model:{value:(_vm.personLocal.notes),callback:function ($$v) {_vm.$set(_vm.personLocal, "notes", $$v)},expression:"personLocal.notes"}})],1)],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")]),_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading || _vm.personLocal.id == 0,"outlined":"","color":"primary"},on:{"click":_vm.deleteAction}},[_vm._v("Delete")])],1)]}}])}),_c('ConfirmDialog',{attrs:{"title":"Confirm delete","description":"Are you sure you want to delete this item","okayLabel":"Yes","cancelLabel":"No","show":_vm.showDeleteConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }