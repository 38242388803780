<template>
  <v-container>
    <v-alert v-if="hasDuplicates" type="warning">There is a duplicate entry for this person in the subgroup</v-alert>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="4">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="personLocal.people_types_id"
                :disabled="loading"
                :items="lists.types"
                item-text="title"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Person Type"
                :error-messages="errors"
                slot-scope="{ errors }"
                :clearable="!isNewRecord"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Status" rules="required">
              <v-select
                v-model="personLocal.people_statuses_id"
                :disabled="loading"
                :items="lists.status"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Status"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Gender" rules="required">
              <v-select
                v-model="personLocal.genders_id"
                :disabled="loading"
                :items="lists.genders"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Gender"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="First Name" rules="required|min:2|max:60">
              <v-text-field
                v-model="personLocal.first_name"
                :disabled="loading"
                label="First Name"
                :error-messages="errors"
                slot-scope="{ errors }"
                @change="onInputChanged"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Surname" rules="required|min:2|max:60">
              <v-text-field
                v-model="personLocal.surname"
                :disabled="loading"
                label="Surname"
                :error-messages="errors"
                slot-scope="{ errors }"
                @change="onInputChanged"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Date of birth" rules="date">
              <DateInput
                v-model="personLocal.dob"
                label="Date of birth"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Email">
              <v-text-field v-model="personLocal.email" :disabled="loading" label="Email"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Mobile">
              <v-text-field v-model="personLocal.mobile" :disabled="loading" label="Mobile"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Notes">
              <v-textarea v-model="personLocal.notes" label="Notes" rows="3" filled auto-grow></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
        <v-btn @click="deleteAction" :disabled="loading || personLocal.id == 0" outlined color="primary" class="ma-2"
          >Delete</v-btn
        >
      </v-form>
    </ValidationObserver>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>

<script>
import { date } from '@/utils/helpers';
import DateInput from '@/views/DateInput';
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import SubgroupService from '@/services/SubgroupService';

extend('required', required);
extend('max', max);
extend('min', min);
extend('date', date);

export default {
  name: 'PersonDetailForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    DateInput,
    ConfirmDialog
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('person', ['loading', 'person', 'error', 'success', 'meta']),
    /* We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        status: [],
        types: [],
        genders: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    personLocal: function () {
      const person = loadDash.cloneDeep(this.person);

      // Set default on new records
      if (this.isNewRecord()) {
        person.people_statuses_id = 1;
        if (this.$route.query.people_types_id) {
          person.people_types_id = parseInt(this.$route.query.people_types_id, 10);
        }

        // People can belong to an organisation
        if (this.$route.query.organisation_id) {
          person.organisation_id = parseInt(this.$route.query.organisation_id, 10);
        }
      }

      return person;
    }
  },
  methods: {
    /**
     * Handle firstname and surname on change
     */
    onInputChanged() {
      this.checkForDuplicates();
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        await store.dispatch('person/save', {
          id: this.personLocal.id,
          person: this.personLocal
        });
        this.snackbar = true;
        this.snackbarMessage = 'Person saved!';
      }
      const subgroup_id = parseInt(this.$route.query.subgroup_id);
      if (subgroup_id > 0) {
        if (this.person.id > 0 && this.isNewRecord()) {
          store.dispatch('subgroup/peopleAttach', {
            subgroup_id: subgroup_id,
            person_id: this.person.id
          });
        }

        this.$router.push({
          path: `/subgroups/${subgroup_id}/namelist`
        });
      }
      this.resetValidation();
    },
    /**
     * Check if this a new record
     */
    isNewRecord() {
      return this.$route.params.id == 'new' ? true : false;
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    },
    /**
     * Check if the form has changed after loading
     */
    isDirty() {
      return this.$refs.objects._data.flags.dirty;
    },
    /**
     * Delete action for the form
     */
    deleteAction() {
      this.showDeleteConfirmDialog = true;
    },
    /**
     * Confirm if item is to be deleted
     */
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    /**
     * Check for duplicates in the system
     */
    async checkForDuplicates() {
      const subgroup_id = parseInt(this.$route.query.subgroup_id);
      if (subgroup_id > 0 && this.personLocal.first_name != '' && this.personLocal.surname != '') {
        const duplicates = await SubgroupService.peopleDuplicates(
          subgroup_id,
          this.personLocal.id,
          this.personLocal.first_name,
          this.personLocal.surname
        );
        if (duplicates && duplicates.data.data.length > 0) {
          this.hasDuplicates = true;
        } else {
          this.hasDuplicates = false;
        }
      }
    },
    /**
     * Delete the item
     */
    processDelete() {
      store.dispatch('person/delete', this.personLocal.id);
    }
  },
  data: () => ({
    showDeleteConfirmDialog: false,
    hasDuplicates: false
  })
};
</script>
