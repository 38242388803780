<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ person.title || 'New Person' }}</h1>
    <v-tabs v-model="tabs" background-color="transparent">
      <v-tab>Details</v-tab>
      <!-- <v-tab>Stays</v-tab>
      <v-tab>Flights</v-tab> -->
    </v-tabs>
    <v-card>
      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item transition="false">
            <v-card flat>
              <v-card-text>
                <PersonDetailForm />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- <v-tab-item transition="false">
            <v-card flat>
              <v-card-text>Stays</v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item transition="false">
            <v-card flat>
              <v-card-text>Flights</v-card-text>
            </v-card>
          </v-tab-item> -->
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import store from '@/store/index';
import { mapGetters } from 'vuex';
import PersonDetailForm from '@/views/PersonDetailForm';

export default {
  name: 'PersonView',
  beforeRouteEnter(to, from, next) {
    store.dispatch('person/get', to.params.id).then(() => {
      next();
    });
  },
  components: {
    PersonDetailForm
  },
  computed: {
    ...mapGetters('person', ['person'])
  },
  data: () => ({
    tabs: null,
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'People',
        exact: true,
        to: '/people'
      },
      {
        text: 'Person',
        disabled: true,
        exact: true,
        to: '/people/:id'
      }
    ]
  })
};
</script>
