import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('h1',{staticClass:"headline pb-4 mx-lg-auto"},[_vm._v(_vm._s(_vm.person.title || 'New Person'))]),_c(VTabs,{attrs:{"background-color":"transparent"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTab,[_vm._v("Details")])],1),_c(VCard,[_c(VCardText,[_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTabItem,{attrs:{"transition":"false"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('PersonDetailForm')],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }